import { graphql, useStaticQuery } from 'gatsby'

const queryAllLeverJobs = () => {
  const {
    allLeverJobs: { nodes }
  } = useStaticQuery(
    graphql`
      query {
        allLeverJobs {
          nodes {
            id
            text
            hostedUrl
            categories {
              team
            }
          }
        }
      }
    `
  )

  const isjobMatched = (target, job) => new RegExp(job, 'i').test(target)

  const computedata = (source, entity, job) => ({
    ...source,
    [job]: source[job] ? [...source[job], entity] : [entity]
  })

  const filteredNodes = nodes.filter(node => !!node.text)

  return filteredNodes.reduce((acc, cur) => {
    switch (true) {
      case isjobMatched(cur.categories.team, 'developer'):
        return computedata(acc, cur, 'developer')
      case isjobMatched(cur.categories.team, 'operations'):
        return computedata(acc, cur, 'operations')
      case isjobMatched(cur.categories.team, 'design'):
        return computedata(acc, cur, 'design')
      case isjobMatched(cur.categories.team, 'product'):
        return computedata(acc, cur, 'product')
      case isjobMatched(cur.categories.team, 'market'):
        return computedata(acc, cur, 'market')
      case isjobMatched(cur.categories.team, 'community'):
        return computedata(acc, cur, 'community')
      case isjobMatched(cur.categories.team, 'user research'):
        return computedata(acc, cur, 'user research')
      default:
        return computedata(acc, cur, 'others')
    }
  }, {})
}

export default queryAllLeverJobs
