import React, { useEffect } from 'react'
import { Element, scroller } from 'react-scroll'
import queryAllLeverJobs from 'src/queries/queryAllLeverJobs'
import queryAllCareersImages from 'src/queries/queryAllCareersImages'
import Img from 'gatsby-image'
import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import capitalize from 'src/utils/capitalize'
import Button from 'src/components/Button/Button'
import Paper from '../components/Paper/Paper'
import styles from './careers.module.scss'

const getImage = (collection, name) => collection[name].childImageSharp.fluid

const scrollToJobList = () => {
  scroller.scrollTo('anchor', {
    duration: 500,
    delay: 0,
    smooth: 'easeInOutQuart'
  })
}

const Careers = props => {
  const data = queryAllLeverJobs()
  const nodes = queryAllCareersImages()

  const mobileBgImage = getImage(nodes, 'mobileImage')
  const desktopBgImage = getImage(nodes, 'desktopImage')
  const logoImage = getImage(nodes, 'logoImage')
  const career1 = getImage(nodes, 'career1')

  return (
    <App>
      <Head />
      <Header pageTitle="Careers" />
      <main className={styles.main}>
        <section className={styles.bannerWrapper}>
          <div className={styles.mobileBgImageWrapper}>
            <div className={styles.bannerInfo}>
              <Img
                fluid={logoImage}
                objectFit="cover"
                objectPosition="50% 50%"
                className={styles.bannerLogo}
              />
              <h1 className={styles.bannerContent}>
                Make the world fun and creative!
              </h1>
              <Button className={styles.bannerButton} onClick={scrollToJobList}>
                View Open Positions
              </Button>
            </div>
            <Img
              fluid={mobileBgImage}
              objectFit="cover"
              objectPosition="50% 50%"
              className={styles.bgImage}
            />
          </div>
          <div className={styles.desktopBgImageWrapper}>
            <div className={styles.bannerInfo}>
              <h1 className={styles.bannerContent}>
                Make the world <br /> fun and creative!
              </h1>
              <Img
                fluid={logoImage}
                objectFit="cover"
                objectPosition="50% 50%"
                className={styles.bannerLogo}
              />
              <Button className={styles.bannerButton} onClick={scrollToJobList}>
                View Open Positions
              </Button>
            </div>
            <Img
              fluid={desktopBgImage}
              objectFit="cover"
              objectPosition="50% 50%"
              className={styles.bgImage}
            />
          </div>
        </section>
        <section className={styles.sectionWrapper}>
          <article>
            <p className={styles.articleContent}>
              We’re constantly growing and evolving. That means we’re always on
              the lookout for talented people to join us on the adventure of
              building a new kind of creative technology company.
            </p>
          </article>
        </section>
        <section className={styles.sectionWrapper}>
          <h2 className={styles.sectionTitle}>Our Culture</h2>
          <article>
            <h4 className={styles.articleTitle}>Customers at Our Core</h4>
            <p className={styles.articleContent}>
              We are driven to combine friendly design and serious technology to
              help people create. We build products that make photos and videos
              more fun and creative for millions in the universe.
            </p>
          </article>
          <article>
            <h4 className={styles.articleTitle}>Always Be Learning</h4>
            <p className={styles.articleContent}>
              We have a strong learning culture and passion for continuous
              improvement. Our team is encouraged to ask questions, challenge
              ideas, and be proactive at work and in communication.
            </p>
          </article>
          <article>
            <h4 className={styles.articleTitle}>
              Investing in Our Team and Community
            </h4>
            <p className={styles.articleContent}>
              Cardinal Blue only grows as much as its people. The ethos of
              Cardinal Blue is to question and learn from one another, iterate
              together, and build exceptional products that make a difference.
            </p>
          </article>
        </section>
        <section className={styles.sectionWrapper}>
          <Paper noHover>
            <Img
              fluid={career1}
              objectFit="cover"
              objectPosition="50% 50%"
              className={styles.sectionImg}
            />
          </Paper>
        </section>
        <section className={styles.sectionWrapper}>
          <h2 className={styles.sectionTitle}>Additional Benefits</h2>
          <ul className={styles.list}>
            <li>Awesome Office</li>
            <li>Flexible Work Time</li>
            <li>Free Lunch and Dinner</li>
            <li>PicCafe</li>
            <li>Sports Classes in Office</li>
            <li>Team Activities and Workshops</li>
          </ul>
        </section>
        <Element name="anchor">
          <section className={styles.jobListSection}>
            <h2 className={`${styles.sectionTitle} ${styles.jobSectionTitle}`}>
              Open Positions
            </h2>
            <a
              href="https://jobs.lever.co/piccollage"
              target="_blank"
              className={`${styles.job} ${styles.jobListLink}`}
            >
              View our open positions here
            </a>
            {[
              'developer',
              'community',
              'design',
              'operations',
              'market',
              'product',
              'user research',
              'others'
            ].map(item => {
              return (
                <article key={item} className={styles.jobGroup}>
                  <header className={styles.jobGroupName}>
                    {item !== 'others'
                      ? data[item] && capitalize(item)
                      : (data[item] &&
                          data[item][0] &&
                          data[item][0]['categories']['team']) ||
                        item}
                  </header>
                  {data[item] &&
                    data[item].map((job = {}) => (
                      <a
                        key={job.id}
                        href={job.hostedUrl}
                        target="_blank"
                        className={styles.job}
                      >
                        {job.text}
                      </a>
                    ))}
                </article>
              )
            })}
          </section>
        </Element>
        <section className={styles.sectionWrapper}>
          <h2 className={styles.sectionTitle}>Application Process</h2>
          <ol className={styles.list}>
            <li>
              Online Video Interview: We want to get to know you better 👋🏻
            </li>
            <li>Quiz: Show us your knowledge and skills ✍🏻 </li>
            <li>
              On-site Interview: You will participate in different tasks and
              situational exercises—this is for both of us to see if we are a
              match 🙂
            </li>
          </ol>
        </section>
      </main>
    </App>
  )
}

export default Careers
