import { graphql, useStaticQuery } from 'gatsby'

const queryAllCareersImages = () => {
  return useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "careers_bg_mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        desktopImage: file(relativePath: { eq: "careers_bg_desktop.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logoImage: file(relativePath: { eq: "careers_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        career1: file(relativePath: { eq: "career1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
}

export default queryAllCareersImages
